/* eslint-disable import/no-cycle */
import React from "react";
import { Plural } from "@lingui/macro";
import { ATTRACTION_COVER_WIDTH } from "../../constants/image/sizes";
import sendEvent from "../../functions/analytics";
import classNames from "../../functions/classNames";
import reverseUrl from "../../functions/reverseUrl";
import getIndependentScreenSize from "../../functions/screen/getIndependentScreenSize";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import CoverImage from "../CoverImage";
import getAttractionCardMaxWidth from "./getAttractionCardMaxWidth";
import "./AttractionCard.css";

/**
 * Attraction card
 * @param {Object} $
 * @param {Number} $.id - id of the attraction
 * @param {Number} $.itemsCount - amount of tours in location
 * @param {String} $.className - additional className for the root Element
 * @param {String} $.name - attraction name
 * @param {String} $.slag - attraction id in the url
 * @param {String} $.preview - background image src
 * @param {String} $.size - size of the card (`"small"`)
 * @param {Object} $.city - description of the city where attraction is located
 * @param {Boolean} $.toursAmount - show amount of tours touches this attraction (`true` by default)
 */
export function AttractionCard({
  id,
  slug,
  name,
  preview,
  itemsCount,
  className,
  toursAmount = true,
  size,
  withTickets = false,
}) {
  const lang = useCurrentLanguage();

  /**
   * Send data into analytics
   */
  const onAttractionClick = () => {
    sendEvent("track", "Attraction click", {
      Refferer: window.location.pathname,
      type: withTickets ? "ticket" : "attraction",
    });
  };

  return (
    <a
      href={reverseUrl(withTickets ? "attraction-tickets-simple" : "attraction-simple", {
        attractionId: id,
        attractionSlug: slug,
        lang,
      })}
      target="_blank"
      className={classNames("AttractionCard", size && `AttractionCard--${size}`, className)}
      onClick={onAttractionClick}
    >
      {preview ? (
        <CoverImage
          width={getAttractionCardMaxWidth(getIndependentScreenSize()) || ATTRACTION_COVER_WIDTH}
          src={preview}
          className="AttractionCard__image"
          alt={name}
        />
      ) : null}
      <span className="AttractionCard__title">{name}</span>
      {toursAmount ? (
        <span className="AttractionCard__description">
          {id === 2409 ? (
            <Plural
              value={itemsCount}
              _0="# guides"
              one="# guide"
              few="# guides"
              many="# guides"
              other="# guides"
            />
          ) : (
            <Plural
              value={itemsCount}
              _0="# tours"
              one="# tour"
              few="# tours and tickets"
              many="# tours and tickets"
              other="# tours and tickets"
            />
          )}
        </span>
      ) : null}
    </a>
  );
}
