import React, { useState, Children } from "react";
import { Icon } from "../../Icon";
import Button from "../../Button";
import "./withArrows.css";

/**
 * Sizes of arrows in dependance with component's size
 * @type {Object}
 */
const arrowSizes = {
  default: "30",
  small: "16",
};

/**
 * Adds navigation buttons to the slider
 * @param {React::Component} ProductsSlider - modified component
 */
export default ProductsSlider =>
  /**
   * ProductsSlider with navigation arrows
   * Removes ability to drag slides, fixes empty space swipes
   * @param {Object} $
   * @param {String} $.size - additional sizes of the component (`"small"`)
   * @param {Number} $.slidesPerPage - amount of slides shown on the page
   * @param {Array[React::Component]} $.children - slides in the component
   * @param {Array[React::Component]} $.additional - additional children, rendering before carousel
   * @param {Boolean} $.arrows - show/hide arrows
   * @param {Function} $.onChange - calls when first visible slide changes
   */
  ({
    children = [],
    additional,
    arrows = true,
    slidesPerPage,
    onChange = () => {},
    size,
    ...props
  }) => {
    const slidesAmount = Children.count(children);
    const arrowsEnabled = arrows && slidesAmount > (slidesPerPage || 1);
    const arrowSize = arrowSizes[size] || arrowSizes.default;
    const [current, setCurrent] = useState(0);

    return (
      <ProductsSlider
        className={arrows ? "ProductsSlider--withArrows" : ""}
        additional={
          <>
            {arrowsEnabled && (
              <>
                <Button
                  className="ProductsSlider__arrow ProductsSlider__arrow--left"
                  disabled={current === 0}
                  icon={<Icon name="chevron/left" width={arrowSize} height={arrowSize} />}
                  onClick={() => setCurrent(current - 1)}
                />
                <Button
                  className="ProductsSlider__arrow ProductsSlider__arrow--right"
                  disabled={current >= slidesAmount - slidesPerPage}
                  icon={<Icon name="chevron/right" width={arrowSize} height={arrowSize} />}
                  onClick={() => setCurrent(current + 1)}
                />
              </>
            )}
            {additional}
          </>
        }
        slidesPerPage={slidesPerPage}
        {...props}
        value={current}
        size={size}
        draggable={false}
        onChange={value => {
          setCurrent(value);
          onChange(value);
        }}
      >
        {children}
      </ProductsSlider>
    );
  };
