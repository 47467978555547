import React from "react";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import reverseUrl from "../../functions/reverseUrl";
import classNames from "../../functions/classNames";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import { getProductLink } from "../../functions/getProductLink";
import "./Breadcrumbs.css";

const getItemClassname = (itemName, current) =>
  classNames("Breadcrumbs__item", current === itemName && "Breadcrumbs__item--active");

export default ({
  country,
  city,
  category,
  attraction,
  page,
  product,
  light,
  theme,
  current,
  withTickets,
  customFirstItem,
}) => {
  const lang = useCurrentLanguage();
  const { categoryId } = useParams();
  const isCategoryActive = String(category?.id) === categoryId;
  return (
    <nav
      className={classNames(
        "Breadcrumbs",
        light && "Breadcrumbs--light",
        theme && `Breadcrumbs--${theme}`,
      )}
    >
      {customFirstItem || (
        <a className={getItemClassname(current, "home")} href={`/${lang}/`}>
          <Trans>Home</Trans>
        </a>
      )}
      {!isEmpty(country) && (
        <a
          className={getItemClassname(current, "country")}
          href={reverseUrl("country", {
            lang,
            countrySlug: country.slug,
            countryId: country.id,
          })}
        >
          {country.name}
        </a>
      )}
      {!isEmpty(city) && (
        <a
          className={getItemClassname(current, "city")}
          href={reverseUrl("city", {
            lang,
            citySlug: city.slug,
            cityId: city.id,
          })}
        >
          {city.name}
        </a>
      )}
      {!isEmpty(category) && (
        <a
          className={getItemClassname(current, "category")}
          href={reverseUrl("category", {
            lang,
            citySlug: city.slug,
            cityId: city.id,
            categoryId: category.id,
            categorySlug: category.slug,
          })}
        >
          {isCategoryActive ? category.title : null}
        </a>
      )}
      {!isEmpty(attraction) && (
        <a
          className={getItemClassname(current, "attraction")}
          href={reverseUrl(`attraction${withTickets ? "-tickets" : ""}-simple`, {
            lang,
            attractionSlug: attraction.slug,
            attractionId: attraction.id,
          })}
        >
          {withTickets ? <Trans>Tickets in {attraction.name}</Trans> : attraction.name}
        </a>
      )}
      {!isEmpty(product) && (
        <a className={getItemClassname(current, "product")} href={getProductLink(lang, product)}>
          {product.title}
        </a>
      )}
      {page && (
        <a className={getItemClassname(current, "page")} href={page.link}>
          {page.name}
        </a>
      )}
    </nav>
  );
};
