import React, { useCallback, useState } from "react";
import get from "lodash/get";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect } from "react-redux";
import LazyHydrate from "react-lazy-hydration";
import * as types from "../../stores/types";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import toQueryString from "../../functions/toQueryString";
import { getViewed } from "../../functions/viewedTours";
import { getProductLink } from "../../functions/getProductLink";
import { fetchOverview } from "../../functions/fetchData";
import removeNullKeys from "../../functions/removeNullKeys";
import reverseUrl from "../../functions/reverseUrl";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchDefaultCurrency } from "../../functions/currency";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import Link from "../../components/Link";
import Button from "../../components/Button";
import Banner from "../../components/Banner";
import TopBar from "../../components/TopBar";
import Root from "../../components/_Root";
import ErrorBoundary from "../../components/ErrorBoundary";
import ProductCard from "../../components/ProductCard";
import Benefits from "../../components/Benefits";
import ProductSlider from "../../components/ProductsSlider";
import withArrows from "../../components/ProductsSlider/withArrows";
import Attractions from "../../components/Attractions";
import CitiesGroup from "../../components/Cities";
import { CustomerReviews } from "../../components/Reviews";
import ProductTabs from "../../components/ProductsTabs";
import ProductsGrid from "../../components/ProductsGrid";
import { CanonicalAuto } from "../../components/Canonical";
import "./Main.css";
import { AlternateAuto } from "../../components/Alternate";
import { DEFAULT_LANG } from "../../constants";
import CookieFooter from "../../components/CookieFooter";

/**
 * Modifying components
 */
const ProductCarousel = withArrows(ProductSlider);

function Viewed({ lang = DEFAULT_LANG, products = [] }) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const slidesPerPage = isDesktop ? 4 : 3;
  const productListId = "viewed";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products, lang);
  }, [productListId, products, lang]);

  return (
    <div className="Main__recently">
      <h2 className="h1 m-32 m-64-t">
        <Trans>Recently viewed</Trans>
      </h2>
      <ScrollHook once="shown" showOn=".Main__recently" onChanged={onProductListShown} />
      <div className="Wrapper__overload">
        <ProductCarousel flatOnMobile chainedScroll slidesPerPage={slidesPerPage}>
          {products.map((product, i) =>
            product ? (
              <div key={product.id}>
                <ProductCard
                  product={product}
                  link={getProductLink(lang, product)}
                  position={i}
                  listId={productListId}
                />
              </div>
            ) : null,
          )}
        </ProductCarousel>
      </div>
    </div>
  );
}

function PredictedOffer({ lang, products = [] }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const isXDesktop = !isSSR && useMediaQuery("(min-width: 1300px)");
  const cityName = products[0].city.name;
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const slidesPerPage = isDesktop ? 4 : 3;
  const productListId = "predicted_offer";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products, lang);
  };

  return (
    <div className="Main__predictedOffer">
      <h2 className="h1 m-32 m-64-t">
        <Trans>Going to {inclineIfNeeded(cityName, "to", lang)}? Look at our recommendations</Trans>
      </h2>
      <ScrollHook once="shown" showOn=".Main__predictedOffer" onChanged={onProductListShown} />
      <div className="Wrapper__overload">
        <ProductCarousel flatOnMobile chainedScroll slidesPerPage={slidesPerPage}>
          {products.map((product, i) => (
            <div key={product.id}>
              <ProductCard
                product={product}
                link={getProductLink(lang, product)}
                position={i}
                listId={productListId}
              />
            </div>
          ))}
        </ProductCarousel>
      </div>
    </div>
  );
}

const Cities = withI18n()(function PureCities({ lang, nearbyCities = [], topCities = [], i18n }) {
  const tabNames = [];
  if (topCities.length) tabNames.push(i18n._(t`Top cities`));
  if (nearbyCities.length) tabNames.push(i18n._(t`Nearby cities`));

  return (
    <div className="Main__cities">
      <ProductTabs tabNames={tabNames}>
        {[topCities.slice(0, 8), nearbyCities.slice(0, 8)].map((data, i) => (
          <div key={i ? "nearby" : "top"} className="Main__citiesGroup">
            <CitiesGroup external cities={data} />
          </div>
        ))}
      </ProductTabs>
      <Link
        arrow
        external
        to={reverseUrl("destinations", { lang })}
        className="Main__more"
        theme="heavy"
      >
        <Trans>Explore all destinations</Trans>
      </Link>
    </div>
  );
});

function Popular({ lang, products = [], location }) {
  const locationName = inclineIfNeeded(get(location, "name"), "in", lang);
  const productListId =
    location && location.name
      ? `${location.slug ? location.slug : "popular_in_country"}_${location.id}`
      : "popular_worldwide";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products, lang);
  };

  return (
    <div className="Main__popular">
      <h2 className="h1 m-32 m-64-t">
        {location && location.name ? (
          <Trans>Popular in {locationName}</Trans>
        ) : (
          <Trans>Popular worldwide</Trans>
        )}
      </h2>
      <ScrollHook once="shown" showOn=".Main__popular" onChanged={onProductListShown} />
      <ProductsGrid withFavorite className="m-24" products={products} listId={productListId} />
    </div>
  );
}

const Destinations = withI18n()(function Destinations({
  lang,
  destinations = [],
  categories = [],
  i18n,
}) {
  const tabNames = [];
  if (destinations.length) tabNames.push(i18n._(t`Top destinations`));
  if (categories.length) tabNames.push(i18n._(t`Top categories`));

  return (
    <div className="Main__destinations">
      <ProductTabs tabNames={tabNames}>
        {[destinations, categories].map((data, i) => (
          <div key={i ? "destinations" : "categories"}>
            {data.map(destination => (
              <Button
                key={destination.id}
                external
                to={reverseUrl("city", {
                  lang,
                  citySlug: destination.slug,
                  cityId: destination.id,
                })}
                theme="tag"
              >
                {destination.name}
              </Button>
            ))}
          </div>
        ))}
      </ProductTabs>
    </div>
  );
});

function Main({
  mobile,
  lang,
  overview = {},
  products = {},
  viewed = [],
  predicted = [],
  destinations = [],
  categories = [],
  currentLocation,
}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobile = isSSR ? mobile : useMediaQuery("(max-width: 767px)");
  const [headerSearch, setHeaderSearch] = useState(false);

  return (
    <Root
      stickyHeader
      hideAppMarkets
      searchInHeader={headerSearch}
      destinations={destinations}
      title={t`WeGoTrip — Audio Tours & Tourist Audio Guides around the world`}
      description={t`WeGoTrip — Find high-quality audio guides with tickets to attractions from experts for self-travel around the world.`}
    >
      <AlternateAuto route="main" />
      <CanonicalAuto route="main" />
      <ScrollHook showOn=".TopBar__search .Search__row" edge="bottom" onChanged={setHeaderSearch} />
      <TopBar />
      <div className="Wrapper">
        <ErrorBoundary>
          {viewed.length ? <Viewed lang={lang} products={viewed} /> : null}
        </ErrorBoundary>
        <ErrorBoundary>
          {predicted.length ? (
            <PredictedOffer lang={lang} products={predicted} isDesktop={!isMobile} />
          ) : null}
        </ErrorBoundary>
        <Benefits className="m-24" />
        <ErrorBoundary>
          {(overview.topCities && overview.topCities.length) ||
          (overview.nearbyCities && overview.nearbyCities.length) ? (
            <Cities lang={lang} {...overview} isDesktop={!isMobile} />
          ) : null}
        </ErrorBoundary>
        <ErrorBoundary>
          {products.products?.length ? (
            <Popular
              isDesktop={!isMobile}
              products={products.products}
              location={currentLocation}
              lang={lang}
            />
          ) : null}
        </ErrorBoundary>
        <ErrorBoundary>
          {overview.topAttractions && overview.topAttractions.length ? (
            <Attractions withTickets attractions={overview.topAttractions} />
          ) : null}
        </ErrorBoundary>
        <CookieFooter />
      </div>
      <LazyHydrate whenVisible>
        <div style={{ width: "100%", overflow: "hidden" }}>
          <div className="Wrapper">
            <CustomerReviews
              className="m-48-adaptive"
              lang={lang}
              isMobile={isMobile}
              withPopup={isMobile}
            />
          </div>
        </div>
        <div className="Wrapper">
          <ErrorBoundary>
            {destinations.length || categories.length ? (
              <Destinations lang={lang} destinations={destinations} categories={categories} />
            ) : null}
          </ErrorBoundary>
        </div>
        <Banner />
      </LazyHydrate>
    </Root>
  );
}

Main.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, ...ctx }) => {
    const userAgent = getUserAgent(req);
    const mobile = isMobileUserAgent(userAgent);

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    /**
     * Getting common information:
     * topCities, nearbyCities, attractions
     */
    const lang = getCurrentLanguage(match.params.lang);
    const overview = await fetchOverview({ lang, expand_overview: "fee,entrance" }, req);

    const {
      currentCity: { id: cityId, itemsCount: cityCount } = {},
      currentCountry: { id: countryId, itemsCount: countryCount } = {},
    } = removeNullKeys(overview);

    /**
     * Fetching popular tours
     * Worldwide or for current city
     */
    if (cityId && cityCount) {
      store.dispatch({ type: types.FETCH_PRODUCTS, cityId, lang });
    } else if (countryId && countryCount) {
      store.dispatch({ type: types.FETCH_PRODUCTS, countryId, lang });
    } else {
      store.dispatch({ type: types.FETCH_PRODUCTS, lang });
    }

    /**
     * Getting recently viewed tours from cookies
     * Creating prediction on city, planned to be visited by user
     * Fetching user
     */
    const cookies = get(req, "headers.cookie");

    store.dispatch({ type: types.FETCH_USER, cookies });
    // TODO: figure out why FETCH_AFFILIATE_PARTNER is not running inside FETCH_USER
    store.dispatch({ type: types.FETCH_AFFILIATE_PARTNER, cookies });
    store.dispatch({ type: types.FETCH_REVIEWS, lang, per_page: mobile ? 3 : 6 });

    /**
     * Forming arrays with destinations and categories
     */
    const destinations = overview.allDestinations || [];
    const categories = destinations.filter(city => city.category);

    const productIds = getViewed(cookies);
    let viewedTours = [];

    if (productIds.length) {
      try {
        const { data } = await Api.get(
          `/api/v2/products/?${toQueryString({
            lang,
            currency,
            product_id: productIds.join(","),
            expand: "images",
            preorder: true,
          })}`,
          {
            lang,
          },
        );
        viewedTours = data.results;
      } catch (error) {
        console.error("Error on loading info about viewed tours", error);
      }
    }

    const viewedCity = get(viewedTours[0], "city.id");
    const predictedTours =
      typeof viewedCity === "number"
        ? await Api.get(
            `/api/v2/products/popular/?${toQueryString({
              lang,
              currency,
              city: viewedCity,
              expand: "images",
              preorder: true,
            })}`,
            { lang },
          )
        : {};

    const result = {
      overview,
      currentLocation:
        (cityCount && overview.currentCity) || (countryCount && overview.currentCountry),
      lang,
      mobile,
      viewed: viewedTours.filter(product => product.locale === lang),
      predicted: get(predictedTours, "data.results", []).filter(
        tour => tour.id !== viewedTours[0].id,
      ),
      destinations,
      categories,
    };

    return result;
  },
);

const mapStateToProps = ({ products }) => ({
  products,
});

export default connect(mapStateToProps)(Main);
