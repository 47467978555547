/* eslint-disable import/no-cycle */
import React from "react";
import { Trans, t } from "@lingui/macro";
import shuffle from "lodash/shuffle";
import { withI18n } from "@lingui/react";
import ProductSlider from "../ProductsSlider";
import { AttractionCard } from "../AttractionCard";
import withArrows from "../ProductsSlider/withArrows";
import ProductTabs from "../ProductsTabs";
import classNames from "../../functions/classNames";
import "./Attractions.css";

const ProductCarousel = withArrows(ProductSlider);

export const TopAttractions = withI18n()(function AttractionsTabs({ attractions, withTickets }) {
  return (
    <div className="Main__cities">
      <Attractions
        key="top-attractions"
        className="m-16-t"
        withHeading={false}
        withTickets={withTickets}
        attractions={attractions}
      />
    </div>
  );
});

export const AttractionsTabs = withI18n()(function AttractionsTabs({ i18n, attractions }) {
  const shuffledAttractions = shuffle(attractions);
  return (
    <div className="Main__cities">
      <ProductTabs tabNames={[i18n._(t`Top attractions`), i18n._(t`Ticket sales`)]}>
        <Attractions
          key="top-attractions"
          className="m-16-t"
          withHeading={false}
          attractions={attractions}
        />
        <Attractions
          key="top-tickets-attractions"
          withTickets
          className="m-16-t"
          withHeading={false}
          attractions={shuffledAttractions}
        />
      </ProductTabs>
    </div>
  );
});

function Attractions({
  withHeading = true,
  attractions = [],
  withTickets = false,
  className,
  slidesPerPage = 4,
}) {
  return attractions.length ? (
    <section className={classNames("Attractions", className)}>
      {withHeading ? (
        <h2 className="h1">
          {withTickets ? <Trans>Tickets to top attractions</Trans> : <Trans>Top attractions</Trans>}
        </h2>
      ) : null}
      <div className="Wrapper__overload">
        <ProductCarousel flatOnMobile slidesPerPage={slidesPerPage}>
          {attractions
            .filter(({ fee, entrance, itemsCount }) =>
              Boolean(withTickets ? fee && entrance && itemsCount : itemsCount),
            )
            .map(attraction => (
              <AttractionCard
                key={attraction.id}
                external
                withTickets={withTickets}
                {...attraction}
              />
            ))}
        </ProductCarousel>
      </div>
    </section>
  ) : null;
}

export default Attractions;
